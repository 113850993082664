import React, {useEffect, useState} from 'react';
import style from './Main.module.css';
import bgImage from 'assets/images/mainBg.jpg';


function Main() {
    const timerPeriod = 1000;
    const [counters, setCounters] = useState([
        {
            start: 0,
            end: 110
        },
        {
            start: 0,
            end: 4865
        },
        {
            start: 0,
            end: 12
        },
        {
            start: 0,
            end: 2
        }
    ]);

    useEffect(() => {
        startTimers();
    }, []);

    const startTimers = () => {
        const timerId = setInterval(() => {
            setCounters(counters.map((counter, index) => {
                const diff = Math.floor(counter.end / (timerPeriod / 10));
                if (counter.start < counter.end && counter.end - counter.start < diff) {
                    counter.start = counter.end;
                } else if (counter.start < counter.end) {
                    counter.start += diff;
                }
                return counter;
            }));
        }, 10);

        const isReady = counters.every(counter => counter.start === counter.end);
        if (isReady) clearInterval(timerId);
    }

    return (
        <div className={style.main} style={{
            background: `url(${bgImage})`,
            backgroundAttachment: 'fixed',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }}>
            <div className={style.mainWrapper}>
                <h3>Hayat Dream Homes Gazipaşa</h3>
                <p>
                    Residential <br/>
                    Turkey, Antalya, Gazipasa<br/>
                    End of construction - December 30, 2024.
                </p>
                <ul className={style.mainCounters}>
                    <li>
                        <span>{counters[0].end}</span>
                        <hr/>
                        <p>Number of apartments</p>
                    </li>
                    <li>
                        <span>{`${counters[1].start} m²`}</span>
                        <hr/>
                        <p>Land area</p>
                    </li>
                    <li>
                        <span>{counters[2].end}</span>
                        <hr/>
                        <p>Floors of the building</p>
                    </li>
                    <li>
                        <span>{`${counters[3].end} years`}</span>
                        <hr/>
                        <p>Payback period</p>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Main;
