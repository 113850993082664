import React from 'react';
import style from './Financial.module.css';

function Financial() {
    return (
        <div className={style.financial}>
            <div className={style.financialWrapper}>
                <h2>Financial</h2>
                <div className={style.financialContent}>
                    <p>
                        <b>Total investment: 4,354,900 USD</b>
                        <br/>
                        <b>IRR: 40%</b>
                        <br/>
                        <b>Payback period: 2 year(s)</b>
                    </p>
                    <ul>
                        <li>Investments attracted on the platform<span>4,354,900 USD</span></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Financial;
