import React from 'react';
import style from './BenefitsAndSocial.module.css';

function BenefitsAndSocial() {
    return (
        <div className={style.about}>
            <div className={style.aboutWrapper}>
                <h2>Project Benefits</h2>
                <p>
                    Gazipasa is a quiet area in the city of Alanya. It is located on a flat area surrounded by mountains
                    that protect from cold northern winds. An important advantage of Gazipasa is the complete absence of
                    industry, this is one of the most environmentally friendly places in all of Turkey. At the same
                    time, the city infrastructure is well developed here: there are chain stores, cafes and restaurants.
                </p>
            </div>
        </div>
    )
}

export default BenefitsAndSocial;
