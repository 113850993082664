import React from 'react';
import style from './Map.module.css';
import GoogleMapReact from 'google-map-react';
import marker from 'assets/images/marker.webp';

function Map() {
    const latitude = 36.297388;
    const longitude = 32.3014382;
    const defaultProps = {
        center: {
            lat: latitude,
            lng: longitude
        },
        zoom: 16
    };

    return (
        <div className={style.map}>
            <div className={style.mapWrapper}>
                <h2>Location</h2>
                <GoogleMapReact
                    bootstrapURLKeys={{key: ""}}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                >
                    <div
                        className={style.marker}
                        lat={defaultProps.center.lat}
                        lng={defaultProps.center.lng}
                    >
                        <img src={marker} width={50} alt={'marker'}/>
                    </div>
                </GoogleMapReact>
            </div>
        </div>
    )
}

export default Map;
