import React from 'react';
import style from './Concept.module.css';

function Concept() {
    return (
        <div className={style.about}>
            <div className={style.aboutWrapper}>
                <h2>Concept</h2>
                <p>
                    Welcome to Hayat Dream Homes, a charming complex nestled in the heart of Alanya. The location is
                    just 520 meters away from the Cleopatra beach, which is a mere 6-7 minute walk. The complex has a
                    closed area and extensive infrastructure for your comfort and convenience.
                </p>
                <p>
                    The location allows you to be in the center of urban life, with all the necessary infrastructure
                    within walking distance. Enjoy easy access to a range of amenities and services, ensuring a
                    hassle-free stay.
                </p>
                <p>
                    Comfort and relaxation here are paramount. The complex boasts 4 blocks of 96 apartments, offering a
                    serene and peaceful environment for your stay.
                </p>
                <p>
                    Enjoy the outdoors in our large pool with slides, children's pool, and relax in the indoor heated
                    pool. The SPA facilities include a sauna and hammam, and relaxation rooms where you can unwind and
                    rejuvenate.
                </p>
                <p>
                    Our outdoor facilities are equally impressive, featuring a barbecue area, gazebo for relaxation,
                    children’s playground, and table tennis. There is also an elevator and parking available for your
                    convenience.
                </p>
                <p>
                    Security is a top priority here, and the complex is under 24/7 video surveillance. Stay connected
                    with Wi-Fi internet, available throughout the complex.
                </p>
                <p>
                    Apartment plans include 1 1, 2 1, and penthouses on the upper floors 2 1 and 3 1, catering to your
                    specific needs and preferences.
                </p>
                <p>
                    Take advantage to purchase a property in 0% instalments during the construction phase. Don't miss
                    out on this exceptional opportunity to own a piece of paradise.
                </p>
            </div>
        </div>
    )
}

export default Concept;
